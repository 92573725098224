<template>
	<div>
		<div class="content flex-direction-column">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
import {EventBus} from '@/utils/event-bus'

export default {
	data() {
		return {}
	},

	mounted() {
		EventBus.$emit('pathArr', this.$route.params.pathArr || this.$route.query.pathArr)
	},

	methods: {}
}
</script>

<style lang="less" scoped></style>
